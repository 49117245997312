var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "archives-detail" },
    [
      _c("div", { staticClass: "archives-detail-toolbar" }, [
        _c(
          "div",
          { staticClass: "archives-detail-back" },
          [
            _c(
              "a-button",
              { attrs: { prefix: "left" }, on: { click: _vm.goBack } },
              [_vm._v("返回")]
            ),
          ],
          1
        ),
      ]),
      _c("UserInfo", {
        attrs: {
          "user-id": _vm.userId,
          "status-type": _vm.statusType,
          "page-type": _vm.pageType,
          "on-job-id": _vm.onJobId,
          "archives-perfect-id": _vm.archivesPerfectId,
          "anchor-height": "calc(100vh - 305px)",
        },
        on: { changeTab: _vm.changeTab },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }