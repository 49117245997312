var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "component-userinfo-contain" }, [
    _c("div", { staticClass: "userinfo-contain" }, [
      _c(
        "div",
        { staticClass: "basic-userinfo-p" },
        [
          _c(
            "basicInfo",
            _vm._b(
              { attrs: { update: _vm.update } },
              "basicInfo",
              _vm.$props,
              false
            )
          ),
        ],
        1
      ),
      _c("div", { staticClass: "tab-userinfo-p" }, [
        _c(
          "div",
          {
            staticClass: "com-tab",
            class: { active: _vm.activeTab == "personal" },
            on: {
              click: function ($event) {
                return _vm.changeTab("personal")
              },
            },
          },
          [_vm._v(" 参保信息 ")]
        ),
        _vm.statusType !== "toExamine"
          ? _c(
              "div",
              {
                staticClass: "com-tab",
                class: { active: _vm.activeTab == "record" },
                on: {
                  click: function ($event) {
                    return _vm.changeTab("record")
                  },
                },
              },
              [_vm._v(" 参保记录 ")]
            )
          : _vm._e(),
      ]),
      _vm.activeTab === "personal"
        ? _c(
            "div",
            { staticClass: "tab-userinfo-content" },
            [
              _vm.insureClassifyList.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.insureClassifyList, function (item, index) {
                      return _c("informationList", {
                        key: index,
                        attrs: { "data-source": item },
                      })
                    }),
                    1
                  )
                : _c("BlankPage", { attrs: { title: "暂无参保信息" } }),
            ],
            1
          )
        : _vm._e(),
      _vm.activeTab === "record"
        ? _c(
            "div",
            { staticClass: "tab-userinfo-content" },
            [
              _vm.infoList.length > 0
                ? _c(
                    "enrollmentRecords",
                    _vm._b(
                      { attrs: { "record-data": _vm.infoList } },
                      "enrollmentRecords",
                      _vm.$props,
                      false
                    )
                  )
                : _c("BlankPage", { attrs: { title: "暂无参保记录" } }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }