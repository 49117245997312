var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "title" }, [
        _c("div", { staticClass: "title-left" }),
        _vm._v(_vm._s(_vm.dataSource.insureClassifyName)),
      ]),
      _c("div", { staticClass: "tabs" }, [
        _c("p", [
          _vm._v("参保城市："),
          _c("span", [_vm._v(_vm._s(_vm.dataSource.cityName))]),
        ]),
        _c("p", [
          _vm._v("参保主体："),
          _c("span", [_vm._v(_vm._s(_vm.dataSource.insureCompanyName))]),
        ]),
        _c("p", [
          _vm._v("参保起始月："),
          _c("span", [_vm._v(_vm._s(_vm.dataSource.startMonth))]),
        ]),
      ]),
      _c("custom-table", {
        staticClass: "custom-table",
        attrs: {
          columns:
            _vm.dataSource.insureClassifyName === "公积金"
              ? _vm.column
              : _vm.columns,
          "data-source": _vm.dataSource.insureTypeList,
          pagination: false,
          "row-key": "insureCode",
        },
        on: { change: _vm.loadDataList },
        scopedSlots: _vm._u([
          {
            key: "companyScale",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("span", [_vm._v(_vm._s(scope.record.companyScale) + "%")]),
              ]
            },
          },
          {
            key: "personScale",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("span", [_vm._v(_vm._s(scope.record.personScale) + "%")]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }