var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "blank-page" }, [
    _c("div", { staticClass: "blank-page-content" }, [
      _c("img", {
        style: "width:90px",
        attrs: { src: require("@/assets/img/home/noData.png"), alt: "" },
      }),
      _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.title))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }