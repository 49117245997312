var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.recordData.length > 0
    ? _c("div", { staticClass: "infomation-record-p" }, [
        _c(
          "div",
          { staticClass: "infomation-anchor" },
          [
            _c("Anchor", {
              ref: "anchor",
              staticClass: "anchor",
              attrs: {
                "title-list": _vm.anchorList,
                container: ".infomation-record",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { ref: "personInfo", staticClass: "infomation-record beauty-scroll" },
          _vm._l(_vm.recordData, function (item, index) {
            return _c("div", { key: index, staticClass: "com-panel" }, [
              _c("div", {
                staticClass: "com-panel-head",
                attrs: { id: "grid_" + item.fieldGroupId },
              }),
              _c(
                "div",
                { staticClass: "com-panel-body" },
                _vm._l(item.children, function (col, key) {
                  return _c("recordList", {
                    key: key,
                    attrs: { "data-list": col },
                  })
                }),
                1
              ),
            ])
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }